<script>
    import { getCustomerList } from 'api/customerList'
    export default {
        name:'JoinUs',
        components: {
            Header: () => import('components/base/Header'),

            'mt-header':app.Mint.Header,
            'mt-button':app.Mint.Button,
        },
        data() {
            return {
                logourl: null,

                header: {
                    mainTitle: getWord('regist_agent'),
                },
            };
        },
        created(){
            if (window.localStorage.getItem('AGENT_OPEN_REQUIRED') && window.localStorage.getItem('AGENT_OPEN_REQUIRED')!=='null') {
                this.$router.push({
                    path: '/login', 
                    query: {
                        selected: "2"
                    }
                });
            }
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },
            getmonny() {
                getCustomerList('1')
                    .then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.length>0) {
                            window.location.href = result.data.result[0].url;
                        }else{
                            app.Mint.Toast(getWord('maintaining2'));
                        }
                })
            }
        },
        mounted() {
            this.logourl = window.localStorage.getItem('logourl');
        }
    };
</script>
<template>
    <section id="join-us" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <Header :mainTitle="header.mainTitle" :backButton=false />
            <div class="main">
                <div class="main_header">
                    <img class="circle" :src="logourl">
                </div>
                <span class="spanCss">{{ getWord('contact_service7') }}</span>
                <mt-button class="main_button" type="primary" @click="getmonny()">{{ getWord('contact_service13') }}</mt-button>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header :mainTitle="header.mainTitle" :backButton=false />
            <div class="main">
                <img class="bg" :src="require('@@/assets/images/personal/bg/join-us.png')" />
                <span class="spanCss">{{ getWord('contact_service7') }}</span>
                <mt-button class="main_button" type="primary" @click="getmonny()">{{ getWord('contact_service13') }}</mt-button>
            </div>
        </template>
    </section>
</template>
<style scoped lang='scss' style="text/css">
#join-us {

    &.template-3 {
        height: 100%;
        background-color: #EEEEEE;

        .main {
            float: none;
            margin: 0.5rem;
            width: auto;
            height: auto;
            border-radius: 0;

            .bg {
                max-width: 100%;
                display: block;
            }

            .spanCss {
                margin: .25rem 0;
            }

            .main_button {
                width: 100%;
                height: auto;
                font-size: .34rem;
                line-height: 1;
                padding: .275rem 0;
                margin: .5rem 0;
                background-color: #EC2829;
            }
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main {
        float: left;
        width: 6.3rem;
        height: 6.5rem;
        background: rgba(255, 255, 255, 1);
        margin: 1rem 0 0 0.6rem;
        border-radius: 0.2rem;

        .main_header {
            float: left;
            width: 6.27rem;
            height: 2.72rem;
            background-image: url('/static/assets/images2/user/agent/lansedi.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .circle {
            max-width: 100%;
            max-height: 80%;
            width: auto;
            height: auto;
            border-radius: 0.2rem;
        }
    }

    .spanCss {
        float: left;
        width: 100%;
        height: 0.3rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        text-align: center;
    }

    .main_button {
        width: 5.39rem;
        height: 0.88rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 0.88rem;
        margin: 2rem 0 0 0.5rem;
    }
}
</style>